import React, { useMemo, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BaseIcon } from 'src/components/BaseIcon';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { get2dAssetIcon } from 'src/utils/assetIcons';

import s from './ListCommunityAssets.module.scss';
import { TListCommunityAssetsTileProps } from './ListCommunityAssets.types';

export const ListCommunityAssetsTile: React.FC<TListCommunityAssetsTileProps> = ({
  asset,
  handleClick,
  selected,
  childrenCount,
  isChildren,
  onMenuClick,
}) => {
  const { t } = useTranslation();

  const modalRefObject = useRef<HTMLDivElement>(null);
  const isOperational = useSelector(selectIsOperationalCommunity);

  const showWifiIcon = useMemo(() => isOperational && !asset.forecastStreamEnabled, [
    isOperational,
    asset,
  ]);
  const showExcecutedIcon = useMemo(() => isOperational && asset.forecastStreamEnabled, [
    isOperational,
    asset,
  ]);

  const onTileClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    handleClick?.();
  };

  return (
    <div
      className={classNames(s.assetTile, {
        [s.isChildren]: isChildren,
        [s.selected]: selected,
      })}>
      <div
        className={classNames(s.leftContainer, {
          [s.clickable]: !!handleClick,
        })}
        onClick={(event) => {
          if (asset?.name === 'Grid Market') return;

          onTileClick(event);
        }}>
        <span>
          <BaseIcon
            className={s.icon}
            icon={asset.name === 'Community' ? 'market-list' : get2dAssetIcon(asset.type)}
            size={12}
          />
        </span>

        <div className={s.nameContainer}>
          <p className={s.name}>{asset.name}</p>
        </div>
      </div>

      {!!childrenCount && (
        <div className={s.childAssetsCount}>
          <span>
            {childrenCount} {t('common.ASSETS')}
          </span>
          <BaseIcon icon="eye" size={12} />
        </div>
      )}
      {showWifiIcon && (
        <div className={classNames(s.wifiCircle, s.withCirclePadding, s.withCircleClickable)}>
          <BaseIcon icon="wifi-notconnected" size={12} />
        </div>
      )}
      {showExcecutedIcon && (
        <div className={classNames(s.wifiCircle, s.wifiExecuted)}>
          <BaseIcon className={s.iconWifi} icon="wifi-white" size={12} />
        </div>
      )}
      {onMenuClick && (
        <div
          className={s.menuIcon}
          ref={modalRefObject}
          onClick={() => {
            if (asset && asset.uuid) {
              onMenuClick(modalRefObject.current, { itemUuid: asset.uuid });
            }
          }}>
          <BaseIcon icon="more-horizontal" size={15} />
        </div>
      )}
    </div>
  );
};
