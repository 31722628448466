import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ChartSelf, EChartSelfType } from 'src/components/Charts/ChartSelf';
import { PieChart } from 'src/components/Charts/PieChart';
import {
  EnergyBillComponents,
  EnergyBillSubtitles,
  TListEnergyBillInfo,
} from 'src/components/EnergyBillComponents';
import { EnergyProfileComponent } from 'src/components/EnergyProfileComponent';
import { HomeEnergyInfo } from 'src/components/HomeEnergyInfo';
import { EPredefinedModalIds } from 'src/constants/modals';
import { DashboardHeader } from 'src/features/Results/DashboardHeader';
import { KeyResultProgress } from 'src/features/Results/SingleAreaResults/KeyResults/components/KeyResultProgress';
import { KeyResultsMember } from 'src/features/Results/SingleAreaResults/KeyResults/KeyResultsMember';
import { TSingleAreaResultsProps } from 'src/features/Results/SingleAreaResults/SingleAreaResults.types';
import {
  EnergyBillInfoContent,
  MyEnergyProfileInfoText,
  SingleHomeNotification,
  SavingsBenchmarkInfoText,
  SelfConsumptionInfoText,
  SelfSufficiencyInfoText,
} from 'src/features/Results/SingleAreaResults/SingleAreaResultsConstants';
import { FEE_MAPPINGS } from 'src/features/Results/SingleAreaResults/SingleAreaResultsConstants';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { useIsAdminInspecting } from 'src/hooks/useIsAdminInspecting';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { usePositionBGM } from 'src/hooks/usePositionBGM';
import { useResults } from 'src/hooks/useResults';
import { useSingleNotification } from 'src/hooks/useSingleNotification';
import {
  selectScreenMode,
  selectSelectedDataResolution,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import { EScreenMode } from 'src/typings/configuration.types';
import { TKpi } from 'src/typings/simulation.types';
import { objectSnakeCase } from 'src/utils/objectSnakeCase';
import { pickKpiData } from 'src/utils/pickKpiData';

import s from './SingleAreaResults.module.scss';
import { createBillInfo, withCurrency } from './utils';

export const SingleAreaResults: React.FC<TSingleAreaResultsProps> = () => {
  const { t } = useTranslation();

  const selectedDataResolution = useSelector(selectSelectedDataResolution);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const screenMode = useSelector(selectScreenMode);

  const { isUserACommunityMember } = useIsUserACommunityMember();
  const { isOwnerCM } = useIsAdminInspecting();
  const { currencyName, currencySymbol, isOperationalCommunity } = useConfiguration();

  const { profileResults, billsDifferences, kpiDifferences } = useResults();

  useSingleNotification({
    singleNotification: SingleHomeNotification,
    checkStatement: !isOperationalCommunity && isOwnerCM,
  });

  const listEnergyBillInfo = useMemo<TListEnergyBillInfo | null>(() => {
    if (Object.keys(billsDifferences).length == 0) return null;

    const {
      spentToCommunity,
      gsyEnergyBillExclRevenueWithoutFees,
      gsyEnergyBillExclRevenue,
      fees,
    } = billsDifferences;

    const taxes = Object.entries(fees).reduce((sum, [key, value]) => {
      if (!key.endsWith('Percent') && key !== 'vat') {
        sum += Number(value);
      }
      return sum;
    }, 0);

    const spentToGrid = gsyEnergyBillExclRevenueWithoutFees - spentToCommunity;
    const spentToGridPercent = spentToGrid / gsyEnergyBillExclRevenue;
    const spentToCommunityPercent = spentToCommunity / gsyEnergyBillExclRevenue;

    const billInfo = [
      createBillInfo(
        '#66EE66',
        t('bill_components.SPENT_TO_GRID'),
        spentToGridPercent,
        spentToGrid,
        currencySymbol,
      ),
      createBillInfo(
        '#FFB347',
        t('bill_components.SPENT_TO_COMMUNITY'),
        !!spentToCommunityPercent ? spentToCommunityPercent : 0,
        !!spentToCommunity ? spentToCommunity : 0,
        currencySymbol,
      ),
      createBillInfo(
        '#FC1355',
        t('bill_components.TAXES'),
        !!gsyEnergyBillExclRevenue ? taxes / gsyEnergyBillExclRevenue : 0,
        !!taxes ? taxes : 0,
        currencySymbol,
      ),
    ];

    FEE_MAPPINGS.forEach(({ key, color, labelKey }) => {
      if (fees.hasOwnProperty(key) && fees[key] > 0) {
        billInfo.push(
          createBillInfo(color, t(labelKey), fees[`${key}Percent`], fees[key], currencySymbol),
        );
      }
    });

    return billInfo;
  }, [billsDifferences, currencySymbol, t]);

  usePositionBGM({
    modalId: EPredefinedModalIds.MODAL_MAP_SIDEBAR,
    top: screenMode === EScreenMode.Mobile ? 120 : isUserACommunityMember ? 73 : 120,
  });

  if (!sidebarExpanded) return null;

  return (
    <div className={s.container}>
      <DashboardHeader className={s.dashboardHeader} />
      <div className={s.keyResultsMember}>
        <KeyResultsMember
          horizontal={true}
          mode={'Home'}
          currency={currencyName}
          homeBillValue={billsDifferences?.gsyEnergyBill}
          homeSavings={billsDifferences?.savings}
          totalBenefit={billsDifferences?.gsyTotalBenefit}
          homeRevenue={
            billsDifferences?.earnedFromGrid + billsDifferences?.earnedFromCommunity || 0
          }
          title={`⚡ ${t('bill_components.ENERGY_BILL')}`}
        />
      </div>
      <div className={s.homeEnergyInfo}>
        <HomeEnergyInfo
          generatedValue={`${
            kpiDifferences?.totalEnergyProducedKwh
              ? kpiDifferences?.totalEnergyProducedKwh.toFixed(0)
              : 0
          } kWh`}
          consumedValue={`${
            kpiDifferences?.totalEnergyDemandedKwh
              ? kpiDifferences?.totalEnergyDemandedKwh.toFixed(0)
              : 0
          } kWh`}
        />
      </div>
      <div className={s.energyBillComponents}>
        {listEnergyBillInfo && (
          <EnergyBillComponents
            key={listEnergyBillInfo.map((item) => item.cost).join('')}
            list={listEnergyBillInfo}
            total={
              billsDifferences?.gsyEnergyBillExclRevenue
                ? withCurrency(currencySymbol, billsDifferences.gsyEnergyBillExclRevenue)
                : ''
            }
            info={<EnergyBillInfoContent />}
            title={`${t('bill_components.ENERGY_BILL_COMPONENTS')}`}
            subTitle={t(EnergyBillSubtitles[selectedDataResolution]) || ''}
          />
        )}
      </div>
      <div className={s.selfSufficiency}>
        <ChartSelf
          kpi={objectSnakeCase<TKpi>(kpiDifferences || {})}
          type={EChartSelfType.Sufficiency}
          className={s.selfItem}
          isDecimalPercentage={true}
          isTitleInfoActive={true}
          isItemInfoActive={false}
          titleInfo={t(SelfSufficiencyInfoText) || ''}
          isCommaSeparated={true}
        />
      </div>
      <div className={s.selfConsumption}>
        <ChartSelf
          kpi={objectSnakeCase<TKpi>(kpiDifferences || {})}
          type={EChartSelfType.Consumption}
          className={s.selfItem}
          isDecimalPercentage={true}
          isTitleInfoActive={true}
          isItemInfoActive={false}
          titleInfo={t(SelfConsumptionInfoText) || ''}
          isCommaSeparated={true}
        />
      </div>
      <div className={s.keyResultProgress}>
        {billsDifferences && (
          <KeyResultProgress
            title={t('MY_ENERGY_SAVINGS_RANKING_IN_COMMUNITY')}
            info={t(SavingsBenchmarkInfoText) || ''}
            percentage={parseInt((billsDifferences.energyBenchmark * 100).toFixed(0))}
            badges={[]}
            className={s.savingsRanking}
          />
        )}
      </div>
      <PieChart
        title="Energy Supply Share"
        items={[
          {
            label: 'Community Energy',
            value: billsDifferences?.boughtFromCommunity || 0,
            info: '',
          },
          {
            label: 'Imported Energy (Utility)',
            value: billsDifferences?.boughtFromGrid || 0,
            info: '',
          },
          {
            label: 'Home Energy',
            value: kpiDifferences?.totalSelfConsumptionKwh || 0,
            info: '',
          },
        ]}
        isDecimalPercentage={true}
        isTitleInfoActive={true}
        isItemInfoActive={false}
        titleInfo={t(SelfSufficiencyInfoText) || ''}
        className={s.energySupplyShare}
      />
      <div className={s.energyProfile}>
        {!!profileResults && (
          <EnergyProfileComponent
            title={t('MY_ENERGY_PROFILE')}
            info={t(MyEnergyProfileInfoText) || ''}
            mode={selectedDataResolution}
            initialData={pickKpiData(profileResults)}
          />
        )}
      </div>
    </div>
  );
};
