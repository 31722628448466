import 'react-tooltip/dist/react-tooltip.css';

import React, { useMemo, useState } from 'react';

import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { ECommunityTabs } from 'src/components/MapSidebar/components/ListMembersAndAssets';
import { TRegistryListProps } from 'src/components/MapSidebar/components/ListMembersAndAssets/components/RegistryList';
import { RegistryListHeader } from 'src/components/MapSidebar/components/ListMembersAndAssets/components/RegistryList/RegistryListHeader';
import { ListCommunityAssets } from 'src/features/Assets/ListAssets/ListCommunityAssets';
import { ListMembers } from 'src/features/Members/ListMembers';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { selectActiveSCMStep, selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { EUserRoles } from 'src/typings/base-types';

import s from './RegistryList.module.scss';

export const CanaryRegistryList: React.FC<TRegistryListProps> = ({
  activeTab,
  setActiveTab,
  wrapperTop,
}) => {
  const userRole = useSelector(selectUserRole);
  const activeSCMStep = useSelector(selectActiveSCMStep);
  const isOperational = useSelector(selectIsOperationalCommunity);

  const [activeFilter, setActiveFilter] = useState<string>('All');
  const [searchValue, setSearchValue] = useState('');
  const [isSelectAllTriggered, setIsSelectAllTriggered] = useState<boolean>(false);

  const showInfoToolTip = useMemo(() => userRole !== EUserRoles.Admin, [userRole]);

  return (
    <div className={s.container}>
      <Tooltip
        anchorId="manage-anchor-element"
        isOpen={
          activeTab === ECommunityTabs.CommunityMembers && activeSCMStep !== 4 && showInfoToolTip
        }
        position={{ x: 350, y: 420 - wrapperTop }}
      />
      <div
        id="manage-anchor-element"
        data-tooltip-html={ReactDOMServer.renderToString(
          <div className={s.tooltipContent}>
            <h3> 👈 Manage members </h3>
            <p>Here you can add or remove already added members or edit their information.</p>
          </div>,
        )}
        data-tooltip-place="right">
        <Tooltip
          anchorId="manage-member-element"
          isOpen={activeTab === ECommunityTabs.CommunityAssets && isOperational && showInfoToolTip}
          position={{ x: 350, y: 550 - wrapperTop }}
        />
        <div
          id="manage-member-element"
          data-tooltip-html={ReactDOMServer.renderToString(
            <div className={s.tooltipContent}>
              <h3> 👈 Click and connect</h3>
              <p>Click to connect single member assets</p>
            </div>,
          )}
          data-tooltip-place="right"></div>
        <RegistryListHeader
          activeTab={activeTab}
          setActiveTab={(val) => {
            setActiveTab(val);
            setIsSelectAllTriggered(false);
          }}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          isSelectAllTriggered={isSelectAllTriggered}
        />
      </div>
      <div>
        {activeTab === ECommunityTabs.CommunityMembers && <ListMembers searchValue={searchValue} />}
        {activeTab === ECommunityTabs.CommunityAssets && (
          <ListCommunityAssets searchValue={searchValue} />
        )}
      </div>
    </div>
  );
};
