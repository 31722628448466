import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import {
  AreaOutput,
  ConfigType,
  ConfigurationOutput,
  Currencies,
  MeterDataStream,
  ProgressInfo,
  ScmAdvancedSettingsOutput,
  ScmCommunityMember,
  SettingsOutput,
  useListMeterDataStreamsQuery,
  useReadConfigurationQuery,
} from 'src/graphql';
import { selectSelectedMemberUuid } from 'src/redux/application/application.selectors';
import {
  selectActiveConfigurationUuid,
  selectSelectedAreaUuid,
  selectSelectedAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import { formatter } from 'src/utils/formatter';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

export type TAreaOutput = AreaOutput & { parentUuid?: string };
export type TAreaUuidToArea = Record<string, TAreaOutput>;

type TUseConfiguration = {
  configuration: ConfigurationOutput | null;
  communityAdvancedSettings: ScmAdvancedSettingsOutput | null;
  settings: SettingsOutput | null;
  currencyName: Currencies;
  currencySymbol: string;
  configType: ConfigType;
  communityArea: TAreaOutput | null;
  communityAreas: AreaOutput[];
  area: AreaOutput | null;
  assets: AreaOutput[];
  areaUuidToArea: TAreaUuidToArea;
  meterDataStreams: MeterDataStream[] | null;
  activeJobUuid: string | null;
  isLoadingReadConfiguration: boolean;
  resultsStatus: string | null;
  resultsProgress: ProgressInfo;
  isOperationalCommunity: boolean;
  isSimulationCommunity: boolean;
  communityLogo: string;
  communityMembers: ScmCommunityMember[];
  selectedMember: ScmCommunityMember | null;
  selectedAsset: TAreaOutput | null;
  isCommunityAreaRepresentationEmpty: boolean;
  skipReadConfiguration: boolean;
  refetchReadConfiguration: () => void;
  startDate: string;
  endDate: string;
};

const TODAY = UTCMoment.utc();
const TOMORROW = UTCMoment.utc().add(7, 'day');

export const useConfiguration = (): TUseConfiguration => {
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const selectedAreaUuid = useSelector(selectSelectedAreaUuid);
  const selectedMemberUuid = useSelector(selectSelectedMemberUuid);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);

  const skipReadConfiguration = useMemo(() => !configUuid || configUuid === 'NEW_COMMUNITY', [
    configUuid,
  ]);

  /* Configuration */
  const {
    data,
    loading: isLoadingReadConfiguration,
    refetch: refetchReadConfiguration,
  } = useReadConfigurationQuery({
    fetchPolicy: 'cache-first',
    skip: skipReadConfiguration,
    variables: { uuid: configUuid! },
  });
  const configuration: ConfigurationOutput | null = useMemo(
    () => data?.readConfiguration as ConfigurationOutput | null,
    [data],
  );
  const configType: ConfigType = useMemo(() => configuration?.type || ConfigType.Collaboration, [
    configuration,
  ]);
  const settings: SettingsOutput | null = useMemo(
    () => configuration?.settingsData as SettingsOutput | null,
    [configuration],
  );
  const currencyName: Currencies = useMemo(() => settings?.currency || Currencies.Eur, [settings]);
  const currencySymbol: string = useMemo(() => formatter.getCurrencySymbol(currencyName), [
    currencyName,
  ]);
  const communityLogo: string = useMemo(() => configuration?.settingsData?.logo || '', [
    configuration?.settingsData?.logo,
  ]);
  const isOperationalCommunity: boolean = useMemo(
    () => configuration?.type === ConfigType.CanaryNetwork || false,
    [configuration],
  );
  const isSimulationCommunity: boolean = useMemo(
    () => configuration?.type === ConfigType.Collaboration || false,
    [configuration],
  );
  const activeJobUuid: string | null = useMemo(() => configuration?.simulationResults || null, [
    configuration,
  ]);
  const resultsStatus: string | null = useMemo(() => configuration?.resultsStatus || null, [
    configuration,
  ]);
  const resultsProgress: ProgressInfo = useMemo(
    () =>
      resultsStatus === 'finished'
        ? { etaSeconds: 0, elapsedTimeSeconds: 0, percentageCompleted: 100 }
        : { etaSeconds: 0, elapsedTimeSeconds: 0, percentageCompleted: 0 },
    [resultsStatus],
  );
  const communityArea: TAreaOutput | null = useMemo(() => {
    const scenario = configuration?.scenarioData?.latest?.serialized
      ? JSON.parse(configuration.scenarioData.latest.serialized)
      : null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const area = objectCamelCase<any>(scenario?.children.find((child) => child.type === 'Area'));
    return { ...area, parentUuid: scenario?.uuid };
  }, [configuration]);
  const communityAreas: AreaOutput[] = useMemo(() => {
    const areas =
      communityArea?.children?.map((
        area, // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) => (area ? objectCamelCase<any>(area) : area)) || [];
    return areas;
  }, [communityArea]);
  const isCommunityAreaRepresentationEmpty = useMemo(() => !!configuration && !communityArea, [
    communityArea,
    configuration,
  ]);
  const area = useMemo(() => {
    const areaUuid = selectedAreaUuid || selectedMemberUuid;
    return (
      communityArea?.children?.find((area): area is AreaOutput => area?.uuid === areaUuid) || null
    );
  }, [communityArea, selectedAreaUuid, selectedMemberUuid]);
  const assets: AreaOutput[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return area && 'children' in area && area.children
      ? area.children.map((asset) => (asset ? objectCamelCase<any>(asset) : asset))
      : [];
  }, [area]);
  const areaUuidToArea: TAreaUuidToArea = useMemo(() => {
    return communityAreas.reduce((acc, area) => {
      acc[area.uuid] = { ...area, parentUuid: communityArea?.uuid };
      return acc;
    }, {} as TAreaUuidToArea);
  }, [communityAreas, communityArea]);
  const communityMembers: ScmCommunityMember[] = useMemo(() => {
    const members = (configuration?.scenarioData?.homeInfo?.scmHomeDetails ?? []).filter(
      (member): member is ScmCommunityMember => member !== null,
    );
    return members;
  }, [configuration]);
  const selectedMember: ScmCommunityMember | null = useMemo(() => {
    const areaUuid = selectedMemberUuid || selectedAreaUuid;
    return communityMembers.find((member) => member.uuid === areaUuid) || null;
  }, [communityMembers, selectedMemberUuid, selectedAreaUuid]);
  const selectedAsset: AreaOutput | null = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return assets.find((asset) => asset?.uuid === selectedAssetUuid) || null;
  }, [assets, selectedAssetUuid]);
  const startDate: string = useMemo(
    () => settings?.startDate || TODAY.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
    [settings?.startDate],
  );
  const endDate: string = useMemo(
    () => settings?.endDate || TOMORROW.format(BACKEND_DATE_FORMATS.SETTINGS_DATA),
    [settings?.endDate],
  );
  const communityAdvancedSettings: ScmAdvancedSettingsOutput | null = useMemo(
    () => configuration?.communityAdvancedSettings || null,
    [configuration],
  );

  /* MeterDataStreams */
  const { data: meterDataStreamsResponse } = useListMeterDataStreamsQuery({
    fetchPolicy: 'cache-first',
    skip: skipReadConfiguration,
    variables: { configUuid: configUuid! },
  });
  const meterDataStreams: MeterDataStream[] | null = useMemo(() => {
    const streams = !!meterDataStreamsResponse?.listMeterDataStreams
      ? meterDataStreamsResponse?.listMeterDataStreams.map((stream) => ({
          areaUuid: stream?.areaUuid || '',
          streamId: stream?.streamId || '',
        }))
      : null;
    return streams;
  }, [meterDataStreamsResponse]);

  return {
    configuration,
    communityAdvancedSettings,
    settings,
    currencyName,
    currencySymbol,
    configType,
    communityArea,
    communityAreas,
    area,
    assets,
    areaUuidToArea,
    meterDataStreams,
    activeJobUuid,
    isLoadingReadConfiguration,
    resultsStatus,
    resultsProgress,
    isOperationalCommunity,
    isSimulationCommunity,
    communityLogo,
    communityMembers,
    selectedMember,
    selectedAsset,
    isCommunityAreaRepresentationEmpty,
    skipReadConfiguration,
    refetchReadConfiguration,
    startDate,
    endDate,
  };
};
