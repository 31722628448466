import 'react-tooltip/dist/react-tooltip.css';

import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { EPredefinedModalIds } from 'src/constants/modals';
import { setSelectedMemberUuid } from 'src/redux/application/application.slice';
import { selectIsAdmin } from 'src/redux/auth/auth.selectors';
import { openModal } from 'src/redux/modals/modals.slice';
import { selectActiveSCMStep } from 'src/redux/scm/scm.selectors';
import { selectIsOperationalCommunity } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';

import s from './UserInvite.module.scss';
import { TUserInviteProps } from './UserInvite.types';

export const UserInvite: React.FC<TUserInviteProps> = ({ wrapperTop }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isOperationalCommunity = useSelector(selectIsOperationalCommunity);
  const activeStep = useSelector(selectActiveSCMStep);
  const isAdmin = useSelector(selectIsAdmin);

  const showInfoToolTip = useMemo(() => !isAdmin, [isAdmin]);
  const onClickAddMember = () => {
    dispatch(setSelectedMemberUuid('NEW_MEMBER'));
  };

  const openUploadDataSheetModal = () => {
    dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
  };

  return (
    <div className={s.container}>
      <div className={s.emailInvitation}>
        <div>
          <BaseButton
            className={s.fullWidthButton}
            size="medium"
            theme="primary"
            onClick={onClickAddMember}>
            {t('commands.ADD_MEMBER')} <BaseIcon icon="plus-circle" size={12} />
          </BaseButton>
          <Tooltip
            anchorId="my-anchor-element"
            isOpen={activeStep !== 4 && showInfoToolTip}
            place="right"
            position={{ x: 350, y: 255 - wrapperTop }}
          />
        </div>
      </div>
      {!isOperationalCommunity && (
        <div className={s.uploadSheetLink} onClick={openUploadDataSheetModal}>
          <BaseIcon icon="file-upload" size={16} />
          {t('commands.UPLOAD_COMMUNITY_DATASHEET')}
        </div>
      )}
      <div className={s.line}></div>
    </div>
  );
};
